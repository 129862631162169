globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"787e5736a9a6ffe64ea46f1d6be61ad290390897"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import {
  breadcrumbsIntegration,
  browserApiErrorsIntegration,
  BrowserClient,
  browserTracingIntegration,
  defaultStackParser,
  functionToStringIntegration,
  getCurrentScope,
  globalHandlersIntegration,
  httpContextIntegration,
  inboundFiltersIntegration,
  makeFetchTransport,
} from '@sentry/nextjs'

import { env } from './src/env.mjs'

/**
 * Indicates whether the current environment anticipates a lot of requests
 * relative to other environments.
 */
const isHighTrafficEnvironment = env.NEXT_PUBLIC_SENTRY_ENVIRONMENT == 'production'

const client = new BrowserClient({
  // all options you normally pass to Sentry.init
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  environment: env.NEXT_PUBLIC_SENTRY_ENVIRONMENT + '-client',
  sampleRate: isHighTrafficEnvironment ? 0.1 : 1.0,
  tracesSampleRate: isHighTrafficEnvironment ? 0.05 : 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: isHighTrafficEnvironment ? 0.2 : 1.0,
  enabled: env.NEXT_PUBLIC_SENTRY_ENABLED,
  // ...
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
  // Only the integrations listed here will be used
  integrations: [
    // common default integrations. Less then 1kb together.
    // dedupeIntegration and linkedErrorsIntegration are lazy loaded
    functionToStringIntegration(),
    inboundFiltersIntegration(),
    // browser default integrations. 13kb together
    breadcrumbsIntegration(),
    browserApiErrorsIntegration(),
    browserTracingIntegration(), // 13kb
    globalHandlersIntegration(),
    httpContextIntegration(),
  ],
})

getCurrentScope().setClient(client)
client.init()

import('./src/lazy-load/sentry').then((lazyImports) => {
  lazyImports.addLazyIntegration()
})
